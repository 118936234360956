import React from "react";
import Image from "../../images/404.png";
import "./Layout.scss";
import { Link } from "@reach/router"

const NotFound = () => {
	return (
		<>
			<div className='notfound'>
				<img src={Image} alt='404' />
				<div className='d-flex flex-column font'>
					<h1>404</h1>
					<h3>Opps page is missing</h3>
					<p>Lets get back to learning!</p>
					<Link to="/classrooms"><h4 className='button'>Classrooms</h4></Link>
				</div>
			</div>
		</>
	);
};

export default NotFound;

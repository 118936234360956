import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
	NotLogin,
	UserNotRsvp,
  ClassroomLimit,
  EnterJitsiClassroom,
  EnterExternalClassroom
} from "./ClassroomPerspective.js";

const UserCTA = ({
  id,
  userList,
  external_url,
  external_platform,
  handleClick
}) => {

	const { auth } = useContext(AuthContext);
	const userId = auth.isAuthenticated && auth.user !== null ? auth.user.id : 0;
	let hasRSVP = userList.indexOf(userId) > -1;
	let maxUsers = 1000;
	let isTooManyUsers = userList.length > maxUsers; 


	function displayBtn() {
		if (!auth.isAuthenticated) {
			return <NotLogin />;
    }
    if (isTooManyUsers) {
				return <ClassroomLimit />
    }

		if (hasRSVP) {
      if (external_url === undefined || external_url === "" || external_url === null) {
        return <EnterJitsiClassroom handleClick={handleClick}/>
      } else {
        return <EnterExternalClassroom external_platform={external_platform} external_url={external_url}/>
      }
    } else {
      return <UserNotRsvp id={id}/>;
    }
	}

	return <>{displayBtn()}</>;
};

export default UserCTA;

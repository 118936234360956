export let initialState = {
  classrooms: [],
  classroom: null,
  loading: true,
};

export let classroomReducer = (state, action) => {
  switch (action.type) {
    case "GET_CLASSROOMS":
      return {
        ...state,
        classrooms: action.payload
      };
    case "GET_CLASSROOM":
      return {
        ...state,
        classroom: action.payload,
      };
    
    case "UPDATE_CLASSROOMS":

      return {
        ...state, classrooms: action.payload
      }
    default:
      return state;
  }
};

import React from "react";
import ReactPlayer from "react-player/lazy"

const HowItWorks = () => {
  return <>
    <h3 className="how-it-works text-center">How It Works</h3>
   
    <div className="container h-100 how-padding">
      <div className="row h-100 justify-content-center align-items-center">
        <ReactPlayer className='react-player' url='https://www.youtube.com/watch?v=E9RIN1kHwlg' controls/>
      </div>
    </div>
  </>;
};

export default HowItWorks;

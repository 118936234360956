import React, { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { AuthContext } from "../../context/AuthContext";
import { Modal, Button } from "react-bootstrap";
import Moment from "moment";
import ReactDatePicker from "react-datepicker";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { updateClassroom } from "../../actions/classroomActions";
import "react-datepicker/dist/react-datepicker.css";
import "./classrooms-form.scss";
import Login from "../auth/Login";

const EditForm = ({ classroom, classrooms, setClassrooms }) => {
	const {
		id,
		topic,
		start_date,
		type,
		duration,
		external_check_box,
		external_platform,
		external_url,
		user_list,
		creator_id,
    description
	} = classroom;

  console.log(classroom)

	const date = Moment(start_date).format("l");
	const time = Moment(start_date).format("h:mm");
	const AM = Moment(start_date).format("A");

	const data = {
		id: id,
		topic: topic,
		date: date,
		time: time,
		AM: AM,
		duration: duration,
		type: type,
		external_check_box: external_check_box,
		external_platform: external_platform,
		external_url: external_url,
		user_list: user_list,
		creator_id: creator_id,
		start_date: start_date,
    description: description
	};

	const { auth } = useContext(AuthContext);
	const [hasPlatform, setHasPlatform] = useState(external_check_box);
	const [showModal, setShowModal] = useState(false);
	const [startDate, setStartDate] = useState(new Date(start_date));
	const [values, setValues] = useState(data);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setValues({ ...values, [name]: value });
	};

	const formatedDate = Moment(startDate).format("YYYY-MM-DD");
	const startTime = Moment(
		`${formatedDate} ${values.time} ${values.AM}`,
		"YYYY-MM-DD hh:mm A"
	).format();

	const endTime = `${Moment(startTime)
		.add(values.duration, "minutes")
		.utc()
		.format()}`;

	const { handleSubmit, register, errors, control } = useForm({
		defaultValues: {
			visibility: true,
		},
	});

	register("visibility", { required: true });
	register("end_date", { required: true });

	//function

	const onSubmit = (values) => {
		console.log(values);
		if (auth.user === null) {
			toast.error("You need to be logged in to Edit a classroom!");
		}
		try {
			updateClassroom(id, classrooms, values, setClassrooms);
			handleModalClose();
		} catch (e) {
			toast.error("Failed to edit classroom");
			console.log(e);
		}
	};

	const handleModalClose = () => setShowModal(false);
	const handleModalShow = () => setShowModal(true);

	return (
		<>
			<button className='edit-time' onClick={handleModalShow}>
				Edit
			</button>

			<Modal show={showModal} onHide={handleModalClose} size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>Start Classroom</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<form>
						<input
							type='hidden'
							name='creator_name'
							value={auth.user.first_name}
							ref={register}
						/>
						<input
							type='hidden'
							name='start_date'
							value={startTime}
							ref={register({ required: true })}
						/>
						<input
							type='hidden'
							name='end_date'
							value={endTime}
							ref={register}
						/>
						<input
							type='hidden'
							name='external_check_box'
							value={hasPlatform}
							ref={register}
						/>
						<input
							type='hidden'
							name='creator_id'
							value={creator_id}
							ref={register}
						/>
						<input
							type='hidden'
							name='user_list'
							value={user_list}
							ref={register}
						/>
						<input type='hidden' name='id' value={id} ref={register} />
						<label className='w-100' htmlFor='topic'>
							Topic <span className='red'>*</span>
							<br></br>
							<input
								className='w-75 p-2'
								type='text'
								name='topic'
								id='topic'
								ref={register({ required: true })}
								value={values.topic}
                maxLength="95"
								onChange={handleInputChange}
							/>
							{errors.topic && (
								<span className='red'>This field is required</span>
							)}
						</label>
						<div className='d-flex align-items-end'>
							<div className='mt-2'>
								Date <span className='red'>*</span>
								<br></br>
								<ReactDatePicker
									name='ReactDatepicker'
                  className='p-2 reactdatepicker'
									selected={startDate}
									onChange={(date) => setStartDate(date)}
                  minDate={Moment().toDate()}
								/>
								{errors.start_date && (
									<span className='red'>This field is required</span>
								)}
							</div>
							<div className='ml-3'>
								Time (your own time) <span className='red'>*</span>
								<br></br>
								<select
									name='time'
									value={values.time}
									onChange={handleInputChange}
                  className='dropdown-padding'
								>
									<option value='12:00'>12:00</option>
									<option value='12:30'>12:30</option>
									<option value='1:00'>1:00</option>
									<option value='1:30'>1:30</option>
									<option value='2:00'>2:00</option>
									<option value='2:30'>2:30</option>
									<option value='3:00'>3:00</option>
									<option value='3:30'>3:30</option>
									<option value='4:00'>4:00</option>
									<option value='4:30'>4:30</option>
									<option value='5:00'>5:00</option>
									<option value='5:30'>5:30</option>
									<option value='6:00'>6:00</option>
									<option value='6:30'>6:30</option>
									<option value='7:00'>7:00</option>
									<option value='7:30'>7:30</option>
									<option value='8:00'>8:00</option>
									<option value='8:30'>8:30</option>
									<option value='9:00'>9:00</option>
									<option value='9:30'>9:30</option>
									<option value='10:00'>10:00</option>
									<option value='10:30'>10:30</option>
									<option value='11:00'>11:00</option>
									<option value='11:30'>11:30</option>
								</select>
								{errors.time && (
									<span className='red'>This field is required</span>
								)}
							</div>
							<div>
								<select
									name='AM'
									value={values.AM}
									onChange={handleInputChange}
                  className='dropdown-padding ml-1'
								>
									<option value='AM'>AM</option>
									<option value='PM'>PM</option>
								</select>
								{errors.am && (
									<span className='red'>This field is required</span>
								)}
							</div>
						</div>
						<label className='w-100' htmlFor='duration'>
							Duration <span className='red'>*</span>
							<br></br>
							<select
								value={values.duration}
								name='duration'
								onChange={handleInputChange}
								ref={register}
                className='dropdown-padding'
							>
								<option value='30'>30 minutes</option>
                <option value='45'>45 minutes</option>
								<option value='60'>1 hour</option>
								<option value='75'>1 hour 15 minutes</option>
								<option value='90'>1.5 hour</option>
								<option value='120'>2 hour</option>
								<option value='180'>2.5 hour</option>
								<option value='240'>3 hour</option>
							</select>
							{errors.duration && (
								<span className='red'>This field is required</span>
							)}
						</label>
						<br></br>
						<label className='mt-2'>
            Add descriptions or goals for this classroom
							<br></br>
						</label>
						<Controller
							control={control}
							name='description'
							className='mt-2'
							render={({ onChange, value }) => (
								<Editor
									apiKey='o8mjpvj9ejj6wotrcrukeg5px8t9iqnr7yd3kdajdfh1i9zs'
                  cloudChannel="dev"
                  value={values.description}
									init={{
										placeholder: "Add descriptions or goals for the classroom",
										height: 150,
										width: "100%",
										menubar: false,
                    plugins: "link textpattern lists",
                    
									}}
									onChange={(e) => onChange(e.target.getContent())}
								/>
							)}
						/>
						<label className='w-100' htmlFor='type'>
							Classroom Type <span className='red'>*</span>
							<br></br>
							<select
								name='type'
								value={values.type}
								ref={register({ required: true })}
								onChange={handleInputChange}
                className='dropdown-padding'
							>
								<option value='Study group led'>Study Group</option>
								<option value='Teacher led'>Teacher led</option>
							</select>
							{errors.type && (
								<span className='red'>This field is required</span>
							)}
						</label>
						<br></br>
						<div className='mt-2'>
							<input
								checked={hasPlatform}
								onChange={() => setHasPlatform(!hasPlatform)}
								id='externalCheckBox'
								type='checkbox'
							/>{" "}
							<label htmlFor='externalCheckBox'>
								Use external platform to host classroom
							</label>
						</div>

						{hasPlatform ? (
							<>
								<label className='w-100' htmlFor='external_platform'>
									Platform
									<span className='red'>*</span>
									<br></br>
									<input
										className='w-75 p-2'
										type='text'
										name='external_platform'
										id='external_platform'
										placeholder='Zoom, Google Hangouts, etc'
										ref={register({ required: true })}
										value={values.external_platform}
										onChange={handleInputChange}
									/>
									{errors.external_platform && (
										<span className='red'>This field is required</span>
									)}
								</label>
								<label className='w-100' htmlFor='external_url'>
									Invite Link
									<span className='red'>*</span>
									<br></br>
									<input
										className='w-75 p-2'
										type='text'
										name='external_url'
										id='external_url'
										value={values.external_url}
										placeholder='Zoom, Google Hangouts, etc'
										ref={register({
											required: true,
											pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
										})}
										onChange={handleInputChange}
									/>
									{errors.external_url?.type === "required" && (
										<span className='red'>This field is required</span>
									)}
									{errors.external_url?.type === "pattern" && (
										<span className='red'>Add a valid url </span>
									)}
								</label>
							</>
						) : (
							<span> </span>
						)}
					</form>
				</Modal.Body>

				<Modal.Footer>
					<Button variant='secondary' onClick={handleModalClose}>
						Close
					</Button>
					<Button variant='primary' onClick={handleSubmit(onSubmit)}>
						Schedule a Classroom
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default EditForm;

import React, { useContext } from "react";
import { GoogleLogin } from "react-google-login";
import { AuthContext } from "../../context/AuthContext";
import { googleAuth } from "../../actions/authActions";

const Login = () => {
  const { setAuth } = useContext(AuthContext);
  const responseGoogle = async ({ tokenId }) => {
    console.log(tokenId);
    try {
      googleAuth(tokenId, setAuth);
    } catch (error) {
      console.error(error.message);
      console.log("GOOGLE SIGNIN ERROR", error.response);
    }
  };
  return (
    <>
      
    <GoogleLogin
      clientId="834171654022-4pb893tekgcuu9mf5oe3on8bgfkdi7h6.apps.googleusercontent.com"
      buttonText="Login"
      scope="profile email"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={"single_host_origin"}
      />
      </>
  );
};

export default Login;

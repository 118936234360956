import React from "react";
import { Link } from "@reach/router"
import write from "../../images/write.png";
import friend from "../../images/friend.png";
import board from "../../images/board.png";

function Main() {
  return (
    <div className="main mt-5 container">
      <div className="main-flex mt-5">
        <div className="main-left-top">
          <img className="main-image" src={write} alt="write" />
        </div>
        <div className="main-right ml-5">
          <h2>Ready to start learning?</h2>
          <h2>We're here to help.</h2>
          <p className="mt-3">
            Congratulations on taking a big step towards a better future! With
            our free GED classrooms, you are sure to learn the material you need
            and get your GED in no time.
          </p>
          <Link to="/howitworks"><button className="mt-3">Learn more</button></Link>
        </div>
      </div>

      <div className="main-flex-middle main-text container">
        <div className="main-left-middle">
          <h2>Looking for a tutor or study buddy?</h2>
          <h2>Join our classrooms now.</h2>
          <p>
            As a member of Squarify, you will have access to our classrooms and
            learn directly from a GED tutor. Find a study buddy in our Discord
            community.
          </p>
          <Link to="/classrooms"><button className="mt-3">Join a classroom</button></Link>
        </div>
        <div className="main-right-middle">
          <img className="main-image" src={friend} alt="friend" />
        </div>
      </div>

      <div className="main-flex main-text pb-5 container">
        <div className="main-left">
          <img className="main-image" src={board} alt="board" />
        </div>
        <div className="main-right">
          <h2>Want to become a GED tutor?</h2>
          <h2>Create a classroom now.</h2>
          <p>
            Are you looking to share your knowledge and help others build a
            better future? Create a classroom on Squarify and equip students
            with the education they need to succeed.{" "}
          </p>
          <Link to="/classrooms"><button className="mt-3">Create a classroom</button></Link>
        </div>
      </div>
    </div>
  );
}

export default Main;

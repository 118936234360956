import React, { useState } from "react";
import api from "../../util/api"
import {Redirect} from "@reach/router"
import { toast } from "react-toastify"

const ResetPassword = (props) => {

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")
  const [success, setSuccess] = useState(false)
  
  const onSubmit = async (e) => {
    e.preventDefault();
    try {

      if (password !== confirmPassword) {
        toast.error("Password are not the same!")
        return;
      }
      const body = { password };

      const resetPassword = await api.post(`/auth/password-reset/${props.token}`, body);
      if (resetPassword.data.success) {
        toast.success(resetPassword.data.message);
        setSuccess(true);
		  }
    } catch (err) {
      console.error(err.message)
      toast.error("This token link is expire");
    }
  }

  if (success) {
    return <Redirect to="/login" noThrow />
  }

  return (
    <>
      <form>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password" />
        <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="confirm password" />
        <input type="submit" onClick={onSubmit}/>
      </form>
      
    </>
  )
};

export default ResetPassword;
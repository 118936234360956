import React, { useReducer } from "react";
import { authReducer, initialState } from "../reducers/authReducer";
let AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  let [auth, setAuth] = useReducer(authReducer, initialState);
  let value = { auth, setAuth };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };

export let initialState = {
	isAuthenticated: false,
	loading: true,
	user: {},
};

export let authReducer = (state, action) => {
	switch (action.type) {
		case "USER_AUTH_SUCCESS":
		case "GOOGLE_AUTH_SUCCESS":
			return {
				...state,
				user: action.payload.data,
				loading: false,
				isAuthenticated: true,
			};
		case "USER_LOADED":
			return {
				...state,
				user: action.payload.data,
				loading: false,
				isAuthenticated: true,
			};
		case "AUTH_ERROR":
		case "LOGOUT":
			return {
				...state,
				isAuthenticated: false,
				loading: false,
			};

		default:
			return state;
	}
};

import React from 'react'
import Navbar from './Navbar'
import Footer from '../shared/Footer'

const TopNavbar = (props) => {
  return (
    <>
    <Navbar props={props} />
    {props.children}
    <Footer />
  </>
  )
}

export default TopNavbar

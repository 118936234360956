import React from "react";
import moment from "moment";
import Interweave from "interweave";
import arrow from "../../images/white-arrow.svg";
import reversearrow from "../../images/reverse-white-arrow.svg";

const dateNow = moment.utc(new Date()).format(); //convert to utc

const ClassroomDetail = ({
	topic,
	description,
	start_date,
	end_date,
	type,
	external_url,
	creator_name,
  show,
  setShow
}) => {
  console.log(start_date)

	const startDate = moment( start_date ).format("l");
	const startTime = moment(start_date).format("h:mm A");
	const endTime = moment(end_date).format("h:mm A");

  const toggleSideBar = () => {
    setShow(!show)
  }

	const showSideBar = () => {
		return (
			<div className='classroom-detail'>
				<div className='d-flex justify-content-end mt-5'>
					<img onClick={toggleSideBar} className='arrow' src={arrow} alt='arrow' />
				</div>
				<div className='text-center mt-5'>
					{dateNow > start_date && dateNow < end_date ? (
							<b>(Live 🔴)</b>
						) : (
							<></>
						)}{" "}
					<h1>
						{topic}
					</h1>
					<p>
						{type} by {creator_name}
					</p>
					<p className='mb-5'>
						{startDate} - {startTime} to {endTime}
					</p>
				</div>
				<p>
					<Interweave className='mt-5' content={description} />
				</p>
				{external_url ? (
					<>
						<h6 className='mt-5'>Classroom URL:</h6>
						<p className="word-wrap">{external_url}</p>
					</>
				) : (
					<></>
				)}
			</div>
		);
	};

  const hideSideBar = () => {
    return (
			<div className='classroom-detail-hide'>
				<div className='d-flex justify-content-end mt-5'>
					<img onClick={toggleSideBar} className='reverse-arrow' src={reversearrow} alt='reverse-arrow' />
				</div>
			</div>
		);
  }

	return (
    show ? showSideBar() : hideSideBar()
		
	);
};

export default ClassroomDetail;

import React from "react";
import ClassroomCTA from "./ClassroomCTA";
import { Link } from "@reach/router";

const Moment = require("moment");

//separate into helper function
const dateNow = Moment.utc(new Date()).format(); //convert to utc

function subtractMinutes(date, num) {
	return Moment(date).subtract(num, "minutes").utc().format();
}

const ClassroomItem = ({
	classroom,
	setClassrooms,
	classrooms,
	photo,
	rsvp,
}) => {
	const {
		id,
		creator_id,
		topic,
		start_date,
		end_date,
		user_list,
		creator_name,
		type,
	} = classroom;

	let startDate = Moment(start_date).format('L') + ' at ' + Moment(start_date).format('LT');
	let status =
		dateNow < subtractMinutes(start_date, 30)
			? "before"
			: dateNow < end_date
			? "live"
			: "over";

	return (
		<>
			{/* This part of codes doesn't require logic: simply display classroom information*/}
      <Link to={`/classroom/${classroom.id}`}>
			<div className='d-flex justify-content-between'>
				<img className='classroom-img' src={photo} alt={photo} />
			</div>
			<h3 className='topic word-wrap'>{topic}</h3>
				<p className='start-date text-center'>{startDate}</p>
			<p className='lead text-center'>{type} by {creator_name}</p>
      <p className='view-more text-right'>View more</p>
      </Link>
			{/* {description && (
				<p className='description'>
          {parsedDescription}
				</p>
			)} */}

			{/* check if classroom is still open*/}

			<ClassroomCTA
				classroomStatus={status}
				classroomId={id}
				creatorId={creator_id}
				userList={user_list}
				classroom={classroom}
				classrooms={classrooms}
				setClassrooms={setClassrooms}
				rsvp={rsvp}
			/>
		</>
	);
};

export default ClassroomItem;

import axios from "axios";

const api = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? "https://squarify-api.herokuapp.com/api/v1" : "http://localhost:5000/api/v1",
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;

import React, { useState } from "react";
import {requestPasswordReset} from "../../actions/authActions"

const RequestPasswordReset = () => {
  const [email, setEmail] = useState("")

  const onSubmit = async (e) => {
    e.preventDefault();
 
    try {
      requestPasswordReset(email);
      setEmail("");
		}
    catch (err) {
      console.error(err.message)
    }
  }
  return (
    <>
      
      <form className="col-lg-6 offset-lg-3">
        <div class="row justify-content-center">
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email" />
          <input type="submit" onClick={onSubmit} />
          </div>
      </form>

      </>
  )
}

export default RequestPasswordReset
import React from "react";
import { Link } from "@reach/router";
import moment from "moment";

function dateFormatConverter(date) {
	const stillUTC = moment.utc(date).toDate();
	return moment(stillUTC).local().format("MMMM Do YYYY, h:mm a");
}

const ClassroomEndItem = ({ classroom }) => {
	const {
		topic,
		start_date,
		creator_name,
		type,
	} = classroom;

	let startDate = dateFormatConverter(start_date);
	return (
		<>
			<div className='d-flex justify-content-between'>
				<p className='classroom-type'>{type}</p>
			</div>
				<h3 className='topic-end-item'>{topic}</h3>
			<p className='start-date'>{startDate}</p>
			<p className='lead'>Led by {creator_name}</p>
		</>
	);
};

export default ClassroomEndItem;

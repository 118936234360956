import React, { useState, useContext } from "react";
import { Jutsu } from "react-jutsu"
import {AuthContext} from "../../context/AuthContext"
import logo from "../../images/logo.png";
import ClassroomCTA from "./ClassroomCTA"

const ClassroomJitsi = ({ dateNow, endDate, id, subject, userList, external_url, external_platform, show}) => {
const {auth} = useContext(AuthContext)
  let [call, setCall] = useState(false)

const handleClick = () => {
  setCall(true)
}

const expandJitsi = () =>{
  return show ? 'jitsi' : 'jitsi w-75'
}

  return (
  <>
      {dateNow < endDate && call ?
        <div className={expandJitsi()}>
          <Jutsu
            roomName={id}
            subject={subject}
            displayName={auth.user.username}
            onMeetingEnd={() => console.log('Meeting has ended')}
            loadingComponent={<h1>ʕ •ᴥ•ʔ jitsi is loading ...</h1>} 
            containerStyles={{ width: '100%', height: '82vh' }}
          />
        </div>
        :
        <div className={expandJitsi()}>
          <div>
            <img className="jitsi-logo" src={logo} alt=""/>
          </div>
          <div>
            <ClassroomCTA userList={userList} external_url={external_url} external_platform={external_platform}
              id={id} handleClick={handleClick} />
          </div>
        </div>
      }
      </>
  )
}

export default ClassroomJitsi
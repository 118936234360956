import React,{useState} from "react";
import Login from "../auth/Login";
import {Modal} from "react-bootstrap"
import {
	RSVP,
} from "../../actions/classroomActions";

//create a classroom when there is too many people

export const ClassroomLimit = () => {
	return (
		<div className="rsvp-container"><button className="rsvp-purple">This classroom is full!</button></div>
	)
}

export const NotLogin = ({ rsvp }) => {

	const [showLogin, setShowLogin] = useState(false);

	const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);
	
	return (
		<div className='rsvp-container'>
			<button className="rsvp-green" onClick={handleShowLogin}>Need to Login to RSVP</button>
			<Modal show={showLogin} onHide={handleCloseLogin}>
        <Modal.Header closeButton>
          <Modal.Title>Login with Google</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div onClick={handleCloseLogin}>
            <Login />
          </div>
        </Modal.Body>
      </Modal> 
		</div>
	);
};

export const UserNotRsvp = ({id}) => {
	return (
		<button onClick={() => RSVP(id)} className="jitsi-button w-100 mt-3">Click here to RSVP to Classroom</button>
	);
};

export const EnterJitsiClassroom = ({ handleClick}) => {
  return (
    <button className="jitsi-button mt-3 w-100" onClick={handleClick}>Enter Classroom</button>
  )
}

export const EnterExternalClassroom = ({external_url, external_platform}) => {
  return (
    <a href={external_url} target="_blank"><button className="jitsi-button mt-3 w-100">Enter {external_platform} Classroom</button></a>
  )
}
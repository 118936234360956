import React, { useEffect, useState} from 'react'
import moment from "moment"
import "./Classroom.scss";
//components
import ClassroomDetail from "./ClassroomDetail"
import ClassroomJitsi from "./ClassroomJitsi"
import api from '../../util/api';

const Classroom = (props) => {
  let [classroom, setClassroom] = useState([])
  let [show, setShow] = useState(true);


  function dateFormatConverter(date) {
    const stillUTC = moment.utc(date).toDate();
    return moment(stillUTC).local().format("MMMM Do YYYY, h:mm a");
  }

  let endDate = dateFormatConverter(classroom.end_date);
   
  useEffect(() => {
    getClassroom();
    window.scrollTo(0, 0)
  }, [])

  const dateNow = moment.utc(new Date()).format(); //convert to utc

  async function getClassroom (){
    try {
      
      let response = await api.get(`/classroom/${props.id}`);
      setClassroom(response.data.data[0]);
    }catch (error){
      console.log(error)
    }
  }

  return (
    <>
      {
        classroom !== [] && classroom.user_list && classroom.user_list.length > 0 ?
        <> 
          <div className='d-flex flex-wrap'>
            <ClassroomDetail
              topic={classroom.topic}
              description={classroom.description}
              start_date={classroom.start_date}
              end_date={classroom.end_date}
              type={classroom.type}
              external_url={classroom.external_url}
              creator_name={classroom.creator_name}
              show={show}
              setShow={setShow}
            />
            <ClassroomJitsi
              dateNow={dateNow}
              endDate={endDate}
              id={classroom.id}
              subject={classroom.topic}
              userList = {classroom.user_list}
              external_url={classroom.external_url}
              external_platform={classroom.external_platform}
              show={show}
              setShow={setShow}
            />
          </div>
        </> 
      : <h1 className='loading d-flex justify-content-center align-items-center'>Loading ...</h1>
    }
    
      </>
  )
}

export default Classroom

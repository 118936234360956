import React from "react";
import "./footer.scss";
import {Link} from "@reach/router"
import logoName from '../../images/logo_name.png'
import logoFooter from '../../images/logo_footer.png'

const Footer = () => {

  return (
    <>
    <div className="footer">
      <div className="container footer-top footer-flex">
        <div>
          <img src={logoFooter} alt="logo Footer"/>
          <img src={logoName} alt="logo name"/>
        </div>
        <div>
          <p className='footer-title'>Classrooms</p>
          <Link className="footer-paragraph" to="/classrooms">Create a classroom</Link>
          <br></br>
          <Link className="footer-paragraph" to="/classrooms">Join a classroom</Link> 
        </div>
        <div>
          <p className='footer-title'>Community</p>
          <Link className="footer-paragraph" to="/codeofconduct">Code of Conduct</Link> 
          <br></br>
          <a className='footer-paragraph' href='https://discord.com/invite/mCGJjy3' target='_blank' rel="noopener noreferrer" className="footer-paragraph">Discord</a>
        </div>
        <div>
          <p className='footer-title'>Feedback</p>
          <a href='https://forms.gle/4YfG7ggsJDpxVUxX9' rel="noopener noreferrer" target='_blank' className="footer-paragraph">Feedback Form</a>
        </div>
      </div>
      <div className="footer-bottom d-flex justify-content-center container mt-5">
        <p>GED® is a registered trademark of the American Council on Education (ACE) and administered exclusively by GED Testing Service LLC under license. This content is not endorsed or approved by ACE or GED Testing Service.</p>
      </div>
    </div>
    </>
  );
};

export default Footer;

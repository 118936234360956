import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
	NotLogin,
	UserNotRsvp,
	UserRsvpClassNotStart,
	UserRsvpClassStart,
	CreatorClassNotStart,
	CreatorClassStart,
	ClassroomLimit
} from "./ClassroomPerspective.jsx";

const UserCTA = ({
	classroomStatus,
	classroomId,
	creatorId,
	userList,
	classroom,
	setClassrooms,
	classrooms,
	rsvp
}) => {
	const { auth } = useContext(AuthContext);
	const userId = auth.isAuthenticated && auth.user !== null ? auth.user.id : 0;

	let hasRSVP = userList.indexOf(userId) > -1;
	let maxUsers = 1000;
	let isTooManyUsers = userList.length > maxUsers; //determine if a classroom is full
	let isOwner = userId === creatorId;

	function displayBtn() {
		if (!auth.isAuthenticated) {
			return <NotLogin rsvp={rsvp} />;
		}

		if (hasRSVP) {
			if (isOwner) {
				if (classroomStatus === "live") {
					return (
						<CreatorClassStart
							classroomId={classroomId}
							classroom={classroom}
							setClassrooms={setClassrooms}
							classrooms={classrooms}
              rsvp={rsvp}
						/>
					);
				}

				return (
					<CreatorClassNotStart
						classroomId={classroomId}
						classroom={classroom}
						setClassrooms={setClassrooms}
						classrooms={classrooms}
            rsvp={rsvp}
					/>
				);
			} else {
				if (classroomStatus === "live") {
					return <UserRsvpClassStart rsvp={rsvp} classroomId={classroomId} />;
				}
				return <UserRsvpClassNotStart rsvp={rsvp} classroomId={classroomId} />;
			}
		} else {
			if (isTooManyUsers) {
				return <ClassroomLimit />
			}
			return <UserNotRsvp rsvp={rsvp} classroomId={classroomId} />;
		}
	}

	return <>{displayBtn()}</>;
};

export default UserCTA;

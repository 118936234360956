import React,{useState} from "react";
import { Link } from "@reach/router";
import Login from "../auth/Login";
import {Modal} from "react-bootstrap"
import {
	RSVP,
	removeRSVP,
	deleteClassroom,
} from "../../actions/classroomActions";

import EditForm from "../classroom-form/EditForm";

//create a classroom when there is too many people

export const ClassroomLimit = () => {
	return (
		<div className="rsvp-container"><button className="rsvp-purple">This classroom is full!</button></div>
	)
}

export const NotLogin = ({ rsvp }) => {

	const [showLogin, setShowLogin] = useState(false);

	const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);
	
	return (
		<div className='rsvp-container'>
			<button className={rsvp} onClick={handleShowLogin}>Need to Login to RSVP</button>
			<Modal show={showLogin} onHide={handleCloseLogin}>
        <Modal.Header closeButton>
          <Modal.Title>Login with Google</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div onClick={handleCloseLogin}>
            <Login />
          </div>
        </Modal.Body>
      </Modal> 
		</div>
	);
};

export const UserNotRsvp = ({ classroomId, rsvp }) => {
	return (
		<div className='rsvp-container'>
			<button className={rsvp} onClick={() => RSVP(classroomId)}>
				RSVP
			</button>
		</div>
	);
};

export const UserRsvpClassNotStart = ({ classroomId, rsvp }) => {
	return (
		<div className='rsvp-container'>
			<button className={rsvp} onClick={() => removeRSVP(classroomId)}>
				Remove RSVP
			</button>
		</div>
	);
};

export const UserRsvpClassStart = ({ classroomId, rsvp}) => {
	return (
			<Link className='rsvp-container' to={`/classroom/${classroomId}`}>
				<button className={rsvp}> Join Live Classroom</button>
			</Link>
	);
};

export const CreatorClassNotStart = ({
	classroomId,
	classrooms,
	setClassrooms,
	classroom,
}) => {
	return (
		<div className='owner d-flex'>
			<EditForm
				classroom={classroom}
				classrooms={classrooms}
				setClassrooms={setClassrooms}
			/>
			<button
				className='delete-time'
				onClick={() => deleteClassroom(classroomId, classrooms, setClassrooms)}
			>
				Delete
			</button>
		</div>
	);
};

export const CreatorClassStart = ({
	classroomId,
	setClassrooms,
	classroom,
	classrooms,
}) => {
	return (
		<div className='owner'>
			<Link to={`/classroom/${classroomId}`}>
				<button className='join-in-live'>Join In</button>
			</Link>
			<EditForm
				classroom={classroom}
				classrooms={classrooms}
				setClassrooms={setClassrooms}
			/>
			<button
				className='delete-time'
				onClick={() => deleteClassroom(classroomId, classrooms, setClassrooms)}
			>
				Delete
			</button>
		</div>
	);
};

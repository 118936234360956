import React, { useState, useEffect} from "react";
import { getClassrooms } from "../../actions/classroomActions";
import ClassroomItem from "./ClassroomItem.jsx";
import "./Classrooms.scss";
import ClassroomEndItem from "./ClassroomEndItem";
import moment from "moment";
import CreateForm from "../classroom-form/CreateForm.jsx";
import teacherLed from '../../images/board.png'
import studyGroup from '../../images/study-group.png'

const dateNow = moment.utc(new Date()).format(); //convert to utc

const Classrooms = () => {
	const [classrooms, setClassrooms] = useState([]);
	const [loading, setLoading] = useState(true);

	// let [search, setSearch] = useState("");

	// const handleChange = (e) => {
	// 	setSearch(e.target.value);
	// };

	// const filteredClassrooms =
	// 	classrooms.length !== undefined
	// 		? classrooms.filter((classroom) => {
	// 				const { topic } = classroom;
	// 				return (
	// 					topic.toLowerCase().includes(search.toLowerCase()) 
	// 				);
	// 		  })
	// 		: [];
	
	// console.log(filteredClassrooms);


	useEffect(() => {
		window.scrollTo(0, 0);
		getClassrooms(setClassrooms);
		setLoading(false);
	}, []);

	const classroomView = (classroom, i) => {
		if(classroom.type === 'Study group led'){
      return (
        <div className='classroom-card-purple mt-3' key={i}>
          <ClassroomItem
            classroom={classroom}
            setClassrooms={setClassrooms}
            classrooms={classrooms}
            photo={studyGroup}
            rsvp='rsvp-purple'
          />
        </div>
      );
    } else{
      return (
        <div className='classroom-card-green mt-3' key={i}>
          <ClassroomItem
            classroom={classroom}
            setClassrooms={setClassrooms}
            classrooms={classrooms}
            photo={teacherLed}
            rsvp='rsvp-green'
          />
        </div>
      );
    }
	};

	return (
		<div className='classrooms'>
			<div className='create-classroom'>
				{/* this can be another component ex. ClassroomsActions.js*/}
				
				<CreateForm className='responsive-center' classrooms={classrooms} setClassrooms={setClassrooms} />
				{/* <span className='ml-5 mr-5 or'>or</span>
				<input
					className='mt-5 search responsive-center'
					type='text'
					placeholder='Search Classroom Topic'
					onChange={handleChange}/> */}
			</div>
			<div className='d-flex justify-content-center mt-5'>
				<h1>Live Future Classrooms</h1>
			</div>

			<div className='d-flex responsive-center flex-wrap mt-5 ml-5'>
				{!loading ? (
					classrooms.map((classroom, i) => {
						if (dateNow < classroom.end_date) {
							return classroomView(classroom, i);
						}
					})
				) : <h1>Loading...</h1>}
			</div>
			{/*
			<div className='d-flex justify-content-center mt-5'>
				<h1>Past Classrooms</h1>
			</div>
			<div className='d-flex responsive-center flex-wrap mt-5 ml-5'>
				{filteredClassrooms !== [] ? (
					filteredClassrooms.map((classroom) =>
						(dateNow > classroom.end_date) ? (
							<div className='classroom-card mt-3'>
								<ClassroomEndItem
									classroom={classroom}
									classrooms={classrooms}
								/>
							</div>
						) : (
							<></>
						)
					)
				) : (
					<>
						<h1>No Classroom</h1>
					</>
				)}
			</div>
				*/}
		</div>
	);
};

export default Classrooms;

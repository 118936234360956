import React from "react";
import "./Layout.scss";
import header from "../../images/header-img.png";
import { Link } from "@reach/router"


function Header() {
  return (
    <div className="header">
      <div className="container header-flex">
        <div className="header-left">
          <h1 className="mt-5">Welcome to Squarify!</h1>
          <h2 className='mt-5'>Find a GED tutor or study buddy - all for free</h2>
          <Link to="/classrooms"><button className="find-classroom mt-5">Find Classrooms</button></Link>
        </div>
        <div className="header-right">
          <img className="header-image" src={header} alt="Classroom" />
        </div>
      </div>
    </div>
  );
}

export default Header;

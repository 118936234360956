import React, { useReducer } from "react";
import { classroomReducer, initialState } from "../reducers/classroomReducer";
let ClassroomContext = React.createContext();

const ClassroomProvider = ({ children }) => {
  let [classroom, setClassroom] = useReducer(classroomReducer, initialState);
  let value = { classroom, setClassroom };

  return (
    <ClassroomContext.Provider value={value}>
      {children}
    </ClassroomContext.Provider>
  );
};

export { ClassroomContext, ClassroomProvider };

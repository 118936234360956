import React, {useEffect} from 'react'
import './Layout.scss'

const CodeofConduct = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="top flex-responsive">
        <h1>Code of Conduct</h1>
      </div>
      <div className="content">
        <p>At Squarify, we value teachers and students. Our goal is to provide a safe space for learning and growth. Therefore, we do not tolerate any form of harassment. Our code of conduct applies to all Squarify platforms, including our website and social media platforms.</p>
        <p className='mt-5'>Harassment includes:</p>
        <ul className='mt-5 ml-5 mb-5'>
          <li>Offensive comments related to gender, disability, mental illness, size, age, race, sexual orientation, or religion.</li>
          <li>Curse words.</li>
          <li>Threats of violence.</li>
          <li>Stalking or following others on an intimate level.</li>
          <li>Sexual images or videos.</li>
          <li>Continued one-on-one communication after requests to cease have been made.</li>
        </ul>
        <p className='mt-5'>We value integrity and having a safe space to learn at Squarify. We welcome all students from all backgrounds and knowledge levels.</p>
        <p className='mt-5'>Members who violate the code of conduct will be approached by Squarify administrators and asked to stop immediately. Members may be banned from Squarify’s website and social media should the violation of the code of conduct not cease. If a member makes you feel unwelcome or uncomfortable, please report it to Squarify as soon as possible. To report an incident, please fill out our <a rel="noopener noreferrer" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScIcC0PAxCYi7euCSb3U3EzIhVLYisqbY7Vo-U6yMmyWhw10A/viewform">report form</a>.</p>
      </div>
    </>
  )
}

export default CodeofConduct


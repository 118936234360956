import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";

//context wrapper

import { AuthProvider } from "./context/AuthContext";
import { ClassroomProvider } from "./context/ClassroomContext";


ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ClassroomProvider>
          <App />
      </ClassroomProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);


import React, { useState, Fragment, useContext } from "react";
import { Nav, Navbar, NavDropdown, Modal } from "react-bootstrap";
import { GoogleLogout } from "react-google-login";
import { Link } from "@reach/router"
import logo from "../../images/logo2.png";
import "./navbar.scss";
import { AuthContext } from "../../context/AuthContext";
import { logout } from "../../actions/authActions";
import logoName from '../../images/logo_name.png'

//components

import Login from "../auth/Login";

const NavBar = () => {
  const [showLogin, setShowLogin] = useState(false);

  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);

  const { auth, setAuth } = useContext(AuthContext);


  // username 

  //let userImg = !auth.loading && auth.user !== undefined ? auth.user.user_img : "";
  let firstName = !auth.loading && auth.user !== undefined && auth.user.first_name !== undefined ? auth.user.first_name : "";

  //navbar function

  const authLinks = (
    <Fragment>
      <Link to="/howitworks">
        <Nav.Link href="#howitworks">How It Works</Nav.Link>
      </Link>
      <Link to="/classrooms">
        <Nav.Link href="classrooms">Classrooms</Nav.Link>
      </Link>

     

      <NavDropdown
        title={firstName}
        id="user-dropdown"
      >
        <NavDropdown.Item>
          {/* <button onClick={() => logout(setAuth)} className="p-3">Logout</button> */}
          <GoogleLogout
            clientId="834171654022-4pb893tekgcuu9mf5oe3on8bgfkdi7h6.apps.googleusercontent.com"
            buttonText="Logout"
            onLogoutSuccess={() => logout(setAuth)}
          ></GoogleLogout> 
        </NavDropdown.Item>
      </NavDropdown>
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      <Link to="/howitworks">
        <Nav.Link href="#howitworks">How It Works</Nav.Link>
      </Link>
      <Link to="/classrooms">
        <Nav.Link href="#classrooms">Classrooms</Nav.Link>
      </Link>    
      <a
        className="login"
        onClick={handleShowLogin}
      >
        Sign In
      </a> 
      {/* Login modal */} 

      <Modal show={showLogin} onHide={handleCloseLogin}>
        <Modal.Header closeButton>
          <Modal.Title>Login with Google</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div onClick={handleCloseLogin}>
            <Login />
          </div>
        </Modal.Body>
      </Modal> 
 
    </Fragment>
  );
  return (
    <div className="App">
      <Navbar className='navbar' collapseOnSelect expand="md">
        <Navbar.Brand>
          <Link to="/">
            <img
              src={logo}
              className="d-inline-block align-top logo"
              alt="Squarify Logo"
            />
            <img
              src={logoName}
              className="d-inline-block align-top logo"
              alt="Squarify Logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>{auth.isAuthenticated ? authLinks : guestLinks}</Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;

import api from "../util/api";
import setAuthToken from "../util/setAuthToken";
import { toast } from "react-toastify";

export async function loadUser(setAuth) {
	try {
		const user = await api.get("/auth/user");

		if (user.data === undefined || user.data === null) {
			setAuth({
				type: "AUTH_ERROR"
			})
		}

		setAuth({
			type: "USER_LOADED",
			payload: user.data,
		});
	} catch (err) {
		console.error(err.message);
		setAuth({
			type: "AUTH_ERROR",
		});
	}
}

//need to create a user login

export async function userLogin(body, setAuth) {
	try {
		const login = await api.post("/auth/login", body);
		
		if (login.data.success) {
			setAuthToken(login.data.token);
			const loadUser = await api.get("/auth/user");

	
			console.log(loadUser)
			setAuth({
				type: "USER_AUTH_SUCCESS",
				payload: loadUser.data,
			});
			toast.success("User Signin Successfully!");
		}
	} catch (err) {
		console.error(err.message);
		setAuth({
			type: "AUTH_ERROR",
		});
		toast.error("Invalid Credential");
	}
}
//need to create a user register

export async function userRegister(body, setAuth) {
	try {
		
		const register = await api.post("/auth/register", body);
		
		if (register.data.success) {
			setAuthToken(register.data.token);
			const loadUser = await api.get("/auth/user");
			setAuth({
				type: "USER_AUTH_SUCCESS",
				payload: loadUser.data,
			});
			toast.success("User Signin Successfully!");
		}
	} catch (err) {
		console.error(err.message);
		setAuth({
			type: "AUTH_ERROR",
		});
		toast.error("Username and email already exist!");
	}
}

export async function googleAuth(tokenId, setAuth) {
	try {
		const body = { idToken: tokenId };
		const login = await api.post("/auth/googlelogin", body);

		if (login.data.success) {
			setAuthToken(login.data.token);
			const loadUser = await api.get("/auth/user");
			setAuth({
				type: "GOOGLE_AUTH_SUCCESS",
				payload: loadUser.data,
			});
			toast.success("User Signin Successfully!");
		}
	} catch (err) {
		console.error(err.message);
		setAuth({
			type: "AUTH_ERROR",
		});
	}
}

export async function logout(setAuth) {
	delete api.defaults.headers.common["x-auth-token"];
	localStorage.removeItem("token");
	setAuth({
		type: "LOGOUT",
	});
}

//password reset

export async function requestPasswordReset(email) {
	try {
		const requestReset = await api.post("/auth/password-reset", {email});

		if (requestReset.data.success) {
			toast.success("We sent a password reset link to your email!");
		}
	} catch (err) {
		console.error(err.message)
	}
}
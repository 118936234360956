import api from "../util/api";
import { toast } from "react-toastify"


//not related to context api function

export const RSVP = async (classroomId) => {
    try {
      let addUser = await api.put(`/classroom/${classroomId}/user`);
      if (addUser.data.success) {
        window.location = `/classroom/${classroomId}`;
      }
    } catch (err) {
      console.error(err.message);
    }
};
  

export const removeRSVP = async (classroomId) => {
    try {
      let removeUser = await api.delete(`/classroom/${classroomId}/user`);

      if (removeUser.data.success) {
        window.location = `/classrooms`;
      }
    } catch (err) {
      console.error(err.message);
    }
  };



//collecting form data
export async function collectFormData(setClassroomForm, data, field) {
  try {
    setClassroomForm({
      type: "COLLECT_FORM_DATA",
      payload: {
        field,
        data,
      },
    });
  } catch (err) {
    console.error(err.message);
  }
}

export async function collectAllEditFormData(setClassroomForm, classroom) {
  try {
    setClassroomForm({
      type: "COLLECT_ALL_EDIT_FORM_DATA",
      payload: {
        classroom
      },
    });
  } catch (err) {
    console.error(err.message);
  }
}

export async function collectEditFormData(setClassroomForm, data, field) {
  try {
    setClassroomForm({
      type: "COLLECT_EDIT_FORM_DATA",
      payload: {
        field,
        data,
      },
    });
  } catch (err) {
    console.error(err.message);
  }
}

//classroom reducer

export async function getClassrooms(setClassrooms) {
  try {
    const {data} = await api.get("/classroom");
    setClassrooms(data.data)
    return data
  } catch (err) {
    console.error(err.message);
  }
}

export async function deleteClassroom(classroomId, classrooms, setClassrooms) {
  try {

    if (window.confirm("Are you sure you want to delete your classroom?")) {
      await api.delete(`classroom/${classroomId}`);
   
      setClassrooms(classrooms.filter((classroom) => classroom.id !== classroomId))
       
      
        toast.success("Classroom Deleted Successfully!")
    }

   
  } catch (err) {
    console.error(err.message)
  }
}


export async function updateClassroom(id, classrooms, formData, setClassrooms) {
 try {
   const updateClassroom = await api.put(`/classroom/${id}`, formData);
   console.log(formData)

   if (updateClassroom.status === 201) {
     setClassrooms(classrooms.map(classroom => classroom.id === id ? formData : classroom))
     console.log(classrooms)
     toast.success("Classroom Updated Successfully!")
   }
 } catch (err) {
   console.error(err.message)
 }
}

export async function createClassroom(formData, setClassrooms, classrooms) {
  try {
    console.log(formData)

    let newClassroomData = await api.post("/classroom", formData);
    console.log(newClassroomData)
    setClassrooms([...classrooms, newClassroomData.data.data])
    return newClassroomData
  } catch (err) {
    console.error(err.message)
  }
}

import React, { useEffect, useContext } from "react";

import { Router } from "@reach/router";
import { ToastContainer } from "react-toastify";
import "react-datetime/css/react-datetime.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/Components/shared/scss/variable.scss";
import "./App.scss";

import HomePage from "./Components/layout/Homepage";
import HowItWorks from "./Components/layout/HowItWorks";
import Classrooms from "./Components/classrooms/Classrooms";
import Classroom from "./Components/classroom/Classroom";
import setAuthToken from "./util/setAuthToken";
import NotFound from "./Components/layout/NotFound";
import CodeofConduct from "./Components/layout/CodeofConduct";
// import UserLogin from "./Components/userLogin/UserLogin"
// import UserRegister from "./Components/userRegister/UserRegister";
import RequestPasswordReset from "./Components/auth/RequestPasswordReset"
import UserResetPassword from "./Components/auth/ResetPassword";
import TopNavbar from "./Components/shared/TopNavbar"

import { AuthContext } from "./context/AuthContext";

//actions
import { loadUser } from "./actions/authActions";

function App() {
	const { setAuth } = useContext(AuthContext);

	//check if the token is still valid and return user info

	useEffect(() => {
		if (localStorage.token) {
			//make sure I get a success
			setAuthToken(localStorage.token);
		}
		loadUser(setAuth);
	}, []);

	return (
		<div className='App'>
			<ToastContainer />
			<Router>
						<TopNavbar path="/">
							<HomePage path="/" />	
							<Classrooms path='/classrooms' />
							<Classroom path='/classroom/:id' />
							<HowItWorks path='/howitworks' />
					<CodeofConduct path='/codeofconduct' />
					<RequestPasswordReset path="/password-reset" />
				<UserResetPassword path="/password-reset/:token" />
					<NotFound default />
					
						</TopNavbar>
			</Router>
		</div>
	);
}

export default App;
